









































































































import { Vue, Component } from "vue-property-decorator";
import { mdiWhatsapp } from "@mdi/js";
import { Getter } from "vuex-class";
import InstallAlert from "@/components/InstallAlert.vue";
import AuthAlert from "@/components/AuthAlert.vue";

@Component({
    components: { AuthAlert, InstallAlert },
})
export default class CompositeAlert extends Vue {
    @Getter("canInstallApp") canInstallApp!: boolean;
    @Getter("user") user!: boolean;

    mdiWhatsapp: string = mdiWhatsapp;

    get priceLockActive() {
        return new Date() >= new Date("2024-05-01");
    }

    get shouldShow(): boolean {
        return (
            this.canInstallApp ||
            !this.user ||
            this.$route.name === this.$constants.ROUTE_NAMES.AIRTIME_INDEX ||
            this.$route.name === this.$constants.ROUTE_NAMES.DSTV_INDEX ||
            this.$route.name === this.$constants.ROUTE_NAMES.ENEO_INDEX ||
            this.$route.name === this.$constants.ROUTE_NAMES.ORDERS_SHOW ||
            this.$route.name === this.$constants.ROUTE_NAMES.CANALPLUS_INDEX ||
            this.$route.name === this.$constants.ROUTE_NAMES.EXCHANGE_INDEX ||
            this.$route.name === this.$constants.ROUTE_NAMES.CAMWATER_INDEX
        );
    }
}
